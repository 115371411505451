// import axios from "axios";
import axios from "../api/axios";
import { BASE_URL } from "./AuthService";

class PatientDataService {
  findPatientById(patientId) {
    return axios.get(`${BASE_URL}/patient/${patientId}/profile`);
  }

  updatePatient(patient) {
    return axios.post(`${BASE_URL}/patient/profile`, patient);
  }

  searchPatient(patient) {
    return axios.post(`${BASE_URL}/patients/searchPatients`, patient);
  }

  searchAllPatient(patient) {
    return axios.post(`${BASE_URL}/patients/searchAllPatients`, patient);
  }

  findAllPatientsByClinic(clinicId, page, size) {
    return axios.get(
      `${BASE_URL}/clinics/${clinicId}/patients?page=${page}&size=${size}`
    );
  }

  createPatient(patient) {
    return axios.post(`${BASE_URL}/createPatientProfile`, patient);
  }

  findFilesByPatient(patientId) {
    return axios.get(`${BASE_URL}/patients/${patientId}/attachment`);
  }

  deletePatientFile(patientId, attachmentId) {
    return axios.delete(
      `${BASE_URL}/patients/${patientId}/attachments/${attachmentId}`
    );
  }

  findPatientDoctors(patientId) {
    return axios.get(`${BASE_URL}/patient/${patientId}/doctorPatient`);
  }

  findAppsByPatient(patientId, clinicId) {
    return axios.get(
      `${BASE_URL}/appointments/clinics/${clinicId}/patient/${patientId}`
    );
  }

  getNotes(patientId) {
    return axios.get(`${BASE_URL}/patient/${patientId}/doctorsMessages`);
  }

  saveNote(note) {
    return axios.post(`${BASE_URL}/patient/saveNoteToPatient`, note);
  }

  getAllSurveys(patientId) {
    return axios.get(`${BASE_URL}/PatientSurvey/allSurveys/${patientId}`);
  }

  unassignPatientFromDoc(patientId, doctorId) {
    return axios.post(`${BASE_URL}/patient/unassignPatientToDoctor`, {
      patientId: `${patientId}`,
      doctorId: `${doctorId}`,
    });
  }

  assignPatientToDoc(assignList) {
    /*    return axios.post(`${BASE_URL}/patient/patientToDoctor`, {
      patientId: `${patientId}`,
      doctorId: `${doctorId}`,
    }); */
    return axios.post(`${BASE_URL}/patient/patientToMultiDoctor`, assignList);
  }

  uploadFile(patientId, file) {
    return axios.post(`${BASE_URL}/patients/${patientId}/fileupload`, file, {});
  }

  uploadSurveyFile(patientId, file) {
    return axios.post(
      `${BASE_URL}/patients/${patientId}/multipleFilesUpload/forSurveyAnswer`,
      file,
      {}
    );
  }

  uploadPhoto(patientId, photo) {
    return axios.post(`${BASE_URL}/patients/${patientId}/photo`, photo, {});
  }
}

export default new PatientDataService();
