import React, { useState, useEffect } from "react";
import PatientList from "./PatientList";
import PatientDetail from "./PatientDetail";
import PatientDataService from "../services/PatientDataService";
import { useAppContext } from "../services/authContext";
import SurveyDataService from "../services/SurveyDataService";

function PatientInfo(props) {
  const { displayMessage, clinicId } = useAppContext();
  const [showDetails, setShowDetails] = useState(false);
  const [patient, setPatient] = useState({});
  const [origPatient, setOrigPatient] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [notes, setNotes] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [apps, setApps] = useState([]);

  const displayDetails = (patientId) => (event) => {
    PatientDataService.findPatientById(patientId).then(
      (response) => {
        let tempPatient = {
          ...response.data,
          lang:
            response.data.lang === "ZH"
              ? 2
              : response.data.lang === "FR"
              ? 1
              : 0,
        };
        console.log(tempPatient);
        setPatient(tempPatient);
        setOrigPatient(response.data);
        getDocs(patientId);
        getMedicalFiles(patientId);
        getNotes(patientId);
        getAllSurveys(patientId);
        getApps(patientId);
      },
      (error) => console.log(error)
    );
    setShowDetails(true);
  };

  function buildPatient(patientId) {
    PatientDataService.findPatientById(patientId).then(
      (response) => {
        let tempPatient = {
          ...response.data,
          lang:
            response.data.lang === "ZH"
              ? 2
              : response.data.lang === "FR"
              ? 1
              : 0,
        };
        console.log(tempPatient);
        setPatient(tempPatient);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function getDocs(patientId) {
    PatientDataService.findPatientDoctors(patientId).then(
      (response) => setDoctors(response.data),
      (error) => console.log(error)
    );
  }

  function getNotes(patientId) {
    PatientDataService.getNotes(patientId).then(
      (response) => setNotes(response.data),
      (error) => console.log(error)
    );
  }

  function getMedicalFiles(patientId) {
    PatientDataService.findFilesByPatient(patientId).then(
      (response) => setAttachments(response.data),
      (error) => console.log(error)
    );
  }

  function getApps(patientId) {
    PatientDataService.findAppsByPatient(patientId, clinicId).then(
      (response) => setApps(response.data.content),
      (error) => console.log(error)
    );
  }

  function getAllSurveys(patientId) {
    PatientDataService.getAllSurveys(patientId).then(
      (response) => {
        let tmpSurveys = [];
        for (const key in response.data) {
          SurveyDataService.findSurveyById(key).then(
            (surveyResponse) => {
              let tempQuestions = [];
              let tempAnswers = new Map();
              surveyResponse.data.surveyQuestions.forEach((question) => {
                let tempOptions = JSON.parse(question.options);
                let patientAnswer = response.data[key].find(
                  (e) => e.questionId === question.id
                );
                if (!patientAnswer) {
                  console.log(
                    "Unable to find question id " +
                      question.id +
                      " in patient question"
                  );
                  return;
                }
                //console.log(patientAnswer.answer);
                if (3 === question.type) {
                  let patientOptionAnswer = patientAnswer.answer
                    .replaceAll('"]', "")
                    .replaceAll('["', "")
                    .replaceAll('"', ",")
                    .split(",");
                  let tempOptionAnswers = [];
                  tempOptions.forEach((option) => {
                    tempOptionAnswers.push(
                      patientOptionAnswer.includes(option.fr) ||
                        patientOptionAnswer.includes(option.en)
                    );
                  });
                  tempAnswers.set(question.id, tempOptionAnswers);
                }
                if (1 === question.type) {
                  tempAnswers.set(
                    question.id,
                    patientAnswer.answer
                      .replaceAll('"]', "")
                      .replaceAll('["', "")
                  );
                }
                if (2 === question.type) {
                  tempAnswers.set(
                    question.id,
                    parseInt(
                      patientAnswer.answer
                        .replaceAll('"]', "")
                        .replaceAll('["', "")
                    )
                  );
                }
                if (4 === question.typ) {
                  tempAnswers.set(question.id, "");
                }
                let tempContext = JSON.parse(question.context);
                tempQuestions.push({
                  ...question,
                  context: tempContext.en,
                  context_fr: tempContext.fr,
                  options: tempOptions,
                });
              });
              tmpSurveys.push({
                surveyId: key,
                title: surveyResponse.data.survey.title,
                createdDate: surveyResponse.data.survey.updatedDate,
                doctorFirstName: surveyResponse.data.firstName,
                doctorLastName: surveyResponse.data.lastName,
                answers: new Map(tempAnswers),
                questions: [...tempQuestions],
              });
            },
            (surveyError) => console.log(surveyError)
          );
        }
        console.log(tmpSurveys);
        setSurveys(tmpSurveys);
      },
      (error) => console.log(error)
    );
  }

  const changePatientInfo = (keyword) => (event) => {
    setPatient({ ...patient, [keyword]: event.target.value });
  };

  const changePhoneType = (type) => (event) => {
    console.log(type);
    setPatient({ ...patient, contactPhoneType: type });
  };

  function showPatientList() {
    setShowDetails(false);
  }

  function updatePatient() {
    let updatingPatient = {
      ...patient,
      lang: patient.lang === 2 ? "ZH" : patient.lang === 1 ? "FR" : "EN",
    };
    PatientDataService.updatePatient(updatingPatient).then(
      (response) => {
        props.populateClinicPatients(props.page, props.rowsPerPage);
        displayMessage("The patient has been successfully updated");
      },
      (error) => console.log(error)
    );
  }

  return (
    <React.Fragment>
      {showDetails ? (
        <PatientDetail
          patient={patient}
          showPatientList={showPatientList}
          attachments={attachments}
          notes={notes}
          doctors={doctors}
          surveys={surveys}
          getMedicalFiles={getMedicalFiles}
          apps={apps}
          changePatientInfo={changePatientInfo}
          changePhoneType={changePhoneType}
          updatePatient={updatePatient}
          getNotes={getNotes}
          buildPatient={buildPatient}
          getDocs={getDocs}
          populateClinicPatients={props.populateClinicPatients}
          page={props.page}
          rowsPerPage={props.rowsPerPage}
        />
      ) : (
        <PatientList
          patientList={props.patientList}
          totalElement={props.totalElement}
          populateClinicPatients={props.populateClinicPatients}
          openNewPat={props.openNewPat}
          displayDetails={displayDetails}
          page={props.page}
          setPage={props.setPage}
          rowsPerPage={props.rowsPerPage}
          setRowsPerPage={props.setRowsPerPage}
          setPatientList={props.setPatientList}
        />
      )}
    </React.Fragment>
  );
}

export default PatientInfo;
