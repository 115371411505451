import React, { useState, useEffect } from "react";
import {
  makeStyles,
  IconButton,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  Button,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { Edit, Delete } from "@material-ui/icons";
import SearchBar from "./SearchBar";
import TablePagination from "@material-ui/core/TablePagination";
import VisibilityIcon from "@material-ui/icons/Visibility";
import "./ContentListStyle.css";
import DoctorDataService from "../services/DoctorDataService";
import { useAppContext } from "../services/authContext";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  head: {
    backgroundColor: "#e2e7ef",
  },
  tHead: {
    fontWeight: 600,
    color: "#777",
  },
  newDoctorBtn: {
    background: "#388EF2",
    borderRadius: 5,
    color: "#F5F8FC",
    textTransform: "capitalize",
  },
}));

function DoctorList(props) {
  const classes = useStyles();
  const { clinicId } = useAppContext();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setSearched] = React.useState(false);
  const totalPageNumber = Math.ceil(props.doctorList.length / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log("Page", newPage + 1, "of", totalPageNumber);
  };

  const handleBackFullList = (event) => {
    setSearched(false);
    props.populateClinicDoctors();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function searchDoctors(doctor) {
    DoctorDataService.searchDoctorsByKey(doctor, clinicId).then(
      (response) => {
        props.setDoctorList(response.data);
        setSearched(true);
      },
      (error) => console.log(error)
    );
  }

  return (
    <React.Fragment>
      {/* <Grid container>
        <Grid item md={6}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="category-label">category</InputLabel>
            <Select
              id="category"
              name="category"
              label="Category"
              variant="outlined"
              value={"1"}
            >
              <MenuItem key="1" value="1">
                Doctor
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>*/}
      {/* <InputLabel id="category-label">Search</InputLabel> */}
      {/*<OutlinedInput
              id="searchBox"
              name="searchBox"
              placeholder="Search"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              }
            />*/}
      {/*</FormControl>*/}
      {/*</Grid>
        <Grid item md={6} align="right">
          <Button
            onClick={props.openNewDoc}
            variant="contained"
            color="primary"
            className={classes.newDoctorBtn}
            startIcon={<AddCircleOutlineIcon />}
          >
            New doctor
          </Button>
        </Grid>
      </Grid > */}
      <SearchBar
        name="doctor"
        openNewIssue={props.openNewDoc}
        searchPeople={searchDoctors}
        populatePeople={props.populateClinicDoctors}
        isDoctor={true}
      />
      <Table className="content-table doctor-table">
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell
              className={"col-title icon-col " + classes.tHead}
            ></TableCell>
            <TableCell className={"col-title large-col " + classes.tHead}>
              Doctor
            </TableCell>
            <TableCell
              className={"col-title medium-col phone-col" + classes.tHead}
            >
              Phone
            </TableCell>
            <TableCell className={"col-title large-col " + classes.tHead}>
              Category
            </TableCell>
            <TableCell className={"col-title medium-col " + classes.tHead}>
              Email
            </TableCell>
            <TableCell className={"action-col " + classes.tHead}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.doctorList.length > 0 ? (
            props.doctorList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((doctor) => (
                <TableRow key={doctor.id}>
                  <TableCell className="col-content icon-col">
                    <Avatar src={doctor.photoUrl} />
                  </TableCell>
                  <TableCell className="col-content bold-font large-col">
                    Dr.{" "}
                    {doctor.firstName +
                      " " +
                      (doctor.middleName ? doctor.middleName + " " : "") +
                      doctor.lastName}
                  </TableCell>
                  <TableCell className="col-content medium-col phone-col">
                    {doctor.contactPhone}
                  </TableCell>
                  <TableCell className="col-content large-col">
                    {doctor.categoryDescriptions.map((description, index) =>
                      "1" === description.isLeaf
                        ? index != 0
                          ? " / " + JSON.parse(description.description).en
                          : JSON.parse(description.description).en
                        : ""
                    )}
                  </TableCell>
                  <TableCell className="col-content medium-col">
                    {doctor.email}
                  </TableCell>
                  <TableCell className="col-content action-col">
                    {/* <IconButton onClick={props.displayDetails(doctor.id)}>
                        <Edit />
                      </IconButton> */}
                    <IconButton>
                      <VisibilityIcon
                        color="primary"
                        variant="contained"
                        onClick={props.displayDetails(doctor.id)}
                      />
                    </IconButton>
                    <IconButton onClick={props.deleteDoctor(doctor.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
          ) : (
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell colSpan={2}>
                <Typography variant="h3">No result found</Typography>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {props.doctorList.length > 0 ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.doctorList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        ""
      )}
      {searched ? (
        <Button
          variant="outlined"
          color="primary"
          style={{ marginLeft: 50, marginTop: 3 }}
          onClick={handleBackFullList}
        >
          Back to Full List
        </Button>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default DoctorList;
