import React, { useState } from "react";
import moment from "moment-timezone";
import {
  makeStyles,
  withStyles,
  Box,
  IconButton,
  Button,
  Typography,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AppointmentDataService from "../services/AppointmentDataService";
import PatientDataService from "../services/PatientDataService";
import SurveyDataService from "../services/SurveyDataService";
import SearchBar from "./SearchBar";
import orderBy from "lodash/orderBy";
import UpArrow from "@material-ui/icons/ArrowUpward";
import DownArrow from "@material-ui/icons/ArrowDownward";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useAppContext } from "../services/authContext";
import "./PatienDetailStyle.css";
import "./ContentListStyle.css";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
}));

function TabPanel(props) {
  const { data, currentIndex, index } = props;

  if (data.length === 0 || data[index].length === 0) {
    return (
      <div
        role="tabpanel"
        hidden={currentIndex !== index}
        id={`full-width-tabpanel-${index}`}
        arial-labelledby={`full-width-tab-${index}`}
      >
        {currentIndex === index && <Box p={3}>No appointment</Box>}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        className="message-container"
        hidden={currentIndex !== index}
        id={`full-width-tabpanel-${index}`}
        arial-labelledby={`full-width-tab-${index}`}
      >
        {currentIndex === index && (
          <Box p={3}>
            {data[index].map((categoryApps, panel) =>
              categoryApps.doctors.length === 0 ? (
                ""
              ) : (
                <Accordion
                  square
                  className="patient-msg-item"
                  expanded={props.expandedPanel === panel}
                  onChange={props.handleAccordionChange(panel)}
                >
                  <AccordionSummary
                    className="expand-icon"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    {/* <Typography variant="subtitle1">
                    {categoryApps.category.name}
                  </Typography> */}
                    <div className="item-title">
                      <div className="title title-from">
                        <div className="content">
                          {categoryApps.category.name}
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="item-content appointment">
                    <Table>
                      <TableBody>
                        {categoryApps.doctors.map((doctorApp) => (
                          <TableRow
                            key={doctorApp.doctor.doctorId}
                            className="appointment-list-row"
                          >
                            <TableCell
                              className="row-td appointement-label"
                              style={{
                                width: "250px",
                                borderRight: "1px solid rgba(0, 0, 0, .125)",
                              }}
                            >
                              Dr.
                              {doctorApp.doctor.firstName}
                              {doctorApp.doctor.middleName
                                ? " " + doctorApp.doctor.middleName
                                : ""}
                              {" " + doctorApp.doctor.lastName}
                            </TableCell>
                            <TableCell
                              align="left"
                              className="row-td appointement-content"
                            >
                              {doctorApp.appointments
                                .sort((a, b) => {
                                  return a.startTime.localeCompare(b.startTime);
                                })
                                .map((appointment) => (
                                  <Button
                                    key={appointment.id}
                                    variant="outlined"
                                    color={
                                      appointment.status === "CANCELLED"
                                        ? "default"
                                        : "primary"
                                    }
                                    style={{ marginRight: 15 }}
                                    onClick={props.openDetails(
                                      appointment.appointmentId
                                    )}
                                  >
                                    {appointment.status === "CANCELLED" ? (
                                      <React.Fragment>
                                        <del>{appointment.startTime}</del>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        {appointment.startTime}
                                      </React.Fragment>
                                    )}
                                  </Button>
                                ))}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              )
            )}
          </Box>
        )}
      </div>
    );
  }
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function AppList(props) {
  const classes = useStyles();
  const { clinicId, clinicTZs } = useAppContext();
  const [expandedPanel, setExpandedPanel] = useState(false);
  const invertDirection = {
    asc: "desc",
    desc: "asc",
  };
  const [columnToSort, setColumnToSort] = useState("appointmentId");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  const handleSort = (columnName) => {
    if (columnToSort === columnName) {
      setSortDirection(invertDirection[sortDirection]);
    } else {
      setColumnToSort(columnName);
      setSortDirection(invertDirection["desc"]);
    }
  };

  function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }
    return true;
  }

  const openDetails = (id) => (event) => {
    AppointmentDataService.getAppDetailsById(id).then(
      (response) => {
        props.setAppDetails({ ...response.data });
        props.setRoom(response.data.roomNum);

        AppointmentDataService.getReminders(id).then(
          (response) => {
            let tmpReminders = [];
            console.log(clinicTZs.get(clinicId));
            response.data.forEach((reminder) => {
              console.log(
                moment
                  .tz(reminder.notifyDue, "UTC")
                  .clone()
                  .tz(clinicTZs.get(clinicId))
                  .format("YYYY-MM-DD HH:mm:ss")
              );
              tmpReminders.push({
                ...reminder,
                notifyDue: moment
                  .tz(reminder.notifyDue, "UTC")
                  .clone()
                  .tz(clinicTZs.get(clinicId))
                  .format("YYYY-MM-DD HH:mm:ss"),
              });
            });

            props.setReminders([...tmpReminders]);
            console.log(tmpReminders);
          },
          (error) => console.log(error)
        );

        AppointmentDataService.getPrepareComment(id).then(
          (response) => {
            props.setComments(response.data.body ? response.data.body : "");
          },
          (error) => console.log(error)
        );

        SurveyDataService.findSurveyByAppId(id).then(
          (response) => {
            console.log(response.data);
            console.log(isEmpty(response.data));
            if (response.data && !isEmpty(response.data)) {
              console.log(Object.keys(response.data)[0]);
              let surveyQuestions =
                response.data[Object.keys(response.data)[0]];
              console.log(surveyQuestions);
              let tempSurvey = response.data;
              SurveyDataService.findSurveyById(
                surveyQuestions[0].surveyId
              ).then(
                (surveyResponse) => {
                  console.log(surveyResponse);
                  let tempQuestions = [];
                  let tempAnswers = new Map();
                  surveyResponse.data.surveyQuestions.forEach((question) => {
                    console.log(question);
                    let tempOptions = JSON.parse(question.options);
                    let patientAnswer = surveyQuestions.find(
                      (e) => e.questionId === question.id
                    );
                    //console.log(patientAnswer.answer);
                    if (3 === question.type) {
                      let patientOptionAnswer = patientAnswer.answer
                        .replaceAll('"]', "")
                        .replaceAll('["', "")
                        .replaceAll('"', ",")
                        .split(",");
                      let tempOptionAnswers = [];
                      tempOptions.forEach((option) => {
                        tempOptionAnswers.push(
                          patientOptionAnswer.includes(option.fr) ||
                            patientOptionAnswer.includes(option.en)
                        );
                      });
                      tempAnswers.set(question.id, tempOptionAnswers);
                    }
                    if (1 === question.type) {
                      tempAnswers.set(
                        question.id,
                        patientAnswer.answer
                          .replaceAll('"]', "")
                          .replaceAll('["', "")
                      );
                    }
                    if (2 === question.type) {
                      let answer = patientAnswer.answer
                        .replaceAll('"]', "")
                        .replaceAll('["', "");
                      let answerId = tempOptions.findIndex(
                        (option) =>
                          option.en.includes(answer) ||
                          option.fr.includes(answer)
                      );
                      tempAnswers.set(question.id, answerId);
                    }
                    if (4 === question.typ) {
                      tempAnswers.set(question.id, "");
                    }
                    let tempContext = JSON.parse(question.context);
                    tempQuestions.push({
                      ...question,
                      context: tempContext.en,
                      context_fr: tempContext.fr,
                      options: tempOptions,
                    });

                    console.log(tempAnswers);
                    console.log(tempQuestions);
                    props.setAnswers(new Map(tempAnswers));
                    props.setQuestions([...tempQuestions]);
                    props.setSurvey({
                      ...tempSurvey,
                      surveyQuestions: surveyResponse.data,
                    });
                  });
                },
                (surveyError) => console.log(surveyError)
              );
            }
          },
          (error) => console.log(error)
        );

        PatientDataService.findPatientById(response.data.patientId).then(
          (response) => {
            props.setPatient(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
        props.displayDetails();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <React.Fragment>
      <SearchBar
        name="appointment"
        openNewIssue={props.openNewApp}
        isApplist={props.isApplist}
        changeAppShow={props.changeAppShow}
        changeCalendarShow={props.changeCalendarShow}
      />
      {/* <Grid container>
        <Grid item md={6}>
          <FormControl className={classes.formControl}>
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              id="category"
              name="category"
              label="Category"
              variant="outlined"
              value={"00"}
            >
              {props.clinicCategories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="category-label">Category</InputLabel>
            <OutlinedInput
              id="searchBox"
              name="searchBox"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={6} align="right">
          <IconButton>
            <ListAltIcon />
          </IconButton>
          <IconButton>
            <TodayIcon color="primary" />
          </IconButton>
          <Button
            onClick={props.openNewApp}
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
          >
            New appointment
          </Button>
        </Grid>
      </Grid> */}
      <Typography variant="subtitle2" align="center">
        <IconButton onClick={props.previousWeek}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        {props.currentDay.clone().day(0).format("MMM D")} -{" "}
        {props.currentDay.clone().day(6).format("ll")}
        <IconButton onClick={props.nextWeek}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </Typography>
      {props.isApplist ? (
        <Table className="content-table app-list-table">
          <TableHead>
            <TableRow>
              <TableCell className="col-title small-col">
                <div
                  onClick={() => handleSort("appointmentId")}
                  className="calendar-table-header"
                >
                  <span>ID</span>
                  {columnToSort === "appointmentId" ? (
                    sortDirection === "asc" ? (
                      <UpArrow />
                    ) : (
                      <DownArrow />
                    )
                  ) : null}
                </div>
              </TableCell>
              <TableCell className="col-title large-col">
                <div
                  onClick={() => handleSort("doctor")}
                  className="calendar-table-header"
                >
                  <span>Doctor</span>
                  {columnToSort === "doctor" ? (
                    sortDirection === "asc" ? (
                      <UpArrow />
                    ) : (
                      <DownArrow />
                    )
                  ) : null}
                </div>
              </TableCell>
              <TableCell className="col-title medium-col">
                <div
                  onClick={() => handleSort("category")}
                  className="calendar-table-header"
                >
                  <span>Category</span>
                  {columnToSort === "category" ? (
                    sortDirection === "asc" ? (
                      <UpArrow />
                    ) : (
                      <DownArrow />
                    )
                  ) : null}
                </div>
              </TableCell>
              <TableCell className="col-title large-col">
                <div
                  onClick={() => handleSort("patientName")}
                  className="calendar-table-header"
                >
                  <span>Patient</span>
                  {columnToSort === "patientName" ? (
                    sortDirection === "asc" ? (
                      <UpArrow />
                    ) : (
                      <DownArrow />
                    )
                  ) : null}
                </div>
              </TableCell>
              <TableCell className="col-title small-col">
                <div
                  onClick={() => handleSort("type")}
                  className="calendar-table-header"
                >
                  <span>Type</span>
                  {columnToSort === "type" ? (
                    sortDirection === "asc" ? (
                      <UpArrow />
                    ) : (
                      <DownArrow />
                    )
                  ) : null}
                </div>
              </TableCell>
              <TableCell className="col-title medium-col">
                <div
                  onClick={() => handleSort("date")}
                  className="calendar-table-header"
                >
                  <span>Date</span>
                  {columnToSort === "date" ? (
                    sortDirection === "asc" ? (
                      <UpArrow />
                    ) : (
                      <DownArrow />
                    )
                  ) : null}
                </div>
              </TableCell>
              <TableCell className="col-title small-col">
                <div
                  onClick={() => handleSort("startTime")}
                  className="calendar-table-header"
                >
                  <span>Time</span>
                  {columnToSort === "startTime" ? (
                    sortDirection === "asc" ? (
                      <UpArrow />
                    ) : (
                      <DownArrow />
                    )
                  ) : null}
                </div>
              </TableCell>
              <TableCell className="col-title statue-col">
                <div
                  onClick={() => handleSort("status")}
                  className="calendar-table-header"
                >
                  <span>Status</span>
                  {columnToSort === "status" ? (
                    sortDirection === "asc" ? (
                      <UpArrow />
                    ) : (
                      <DownArrow />
                    )
                  ) : null}
                </div>
              </TableCell>
              <TableCell className="col-title action-col"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderBy(props.appList, columnToSort, sortDirection).map((app) => (
              <TableRow>
                <TableCell className="col-content small-col">
                  {app.appointmentId}
                </TableCell>
                <TableCell className="col-content large-col bold-font">
                  {app.doctor}
                </TableCell>
                <TableCell className="col-content medium-col">
                  {app.category}
                </TableCell>
                <TableCell className="col-content large-col bold-font">
                  {app.patientName}
                </TableCell>
                <TableCell className="col-content small-col">
                  {app.type}
                </TableCell>
                <TableCell className="col-content medium-col">
                  {app.date}
                </TableCell>
                <TableCell className="col-content small-col">
                  {app.startTime}
                </TableCell>
                <TableCell className="col-content statue-col">
                  <span className={"point-area " + app.status}></span>
                  {app.status}
                </TableCell>
                <TableCell className="col-content action-col">
                  <IconButton>
                    <VisibilityIcon
                      color="primary"
                      variant="contained"
                      onClick={openDetails(app.appointmentId)}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <React.Fragment>
          <div className="tab-section">
            <span className="tab-category-title">Category</span>
            <Tabs
              className="secondary-tab"
              indicatorColor="primary"
              value={props.currentTabIndex}
              onChange={props.handleTabChange}
            >
              {props.dayTabs.map((dayLabel, index) => (
                <Tab label={dayLabel} {...a11yProps(index)} />
              ))}
            </Tabs>
            {props.dayTabs.map((dayLabel, index) => (
              <TabPanel
                currentIndex={props.currentTabIndex}
                expandedPanel={expandedPanel}
                handleAccordionChange={handleAccordionChange}
                index={index}
                data={props.appointments}
                openDetails={openDetails}
              />
            ))}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default AppList;
