import axios from "axios";

//export const BASE_URL = process.env.REACT_APP_BACKEND_API;
//export const BASE_URL = "http://medinetwork.us-east-1.elasticbeanstalk.com/api";
export const BASE_URL = "https://medinetwork-test.cliniplus.net/api";
//  "http://medinetwork-env.p46jeep7wf.us-east-1.elasticbeanstalk.com/api";
//export const BASE_URL =
//  "https://medinetwork.us-east-1.elasticbeanstalk.com/api";
//export const BASE_URL =
//  "http://Medinetwork-env-2.p46jeep7wf.us-east-1.elasticbeanstalk.com/api";
export const USER_NAME_SESSION_ATTRIBUTE_NAME = "authenticatedUser";
export const TOKEN = "token";
export const LOGIN_TIME = "loginTime";
export const USER_FULL_NAME = "userFullName";
export const USERNAME = "userName";
export const ROLE = "role";
export const ACCOUNT = "account";
export const CLINIC_ID = "clinicId";
export const CLINIC_NAME = "clinicName";
export const ACCOUNT_ID = "accountId";

class AuthService {
  login(email, password) {
    const roleId = "3";
    return axios.post(`${BASE_URL}/signin`, { email, password, roleId });
  }

  forgetpw(identity) {
    return axios.post(`${BASE_URL}/forgotpassword`, identity);
  }

  resetpw(identity) {
    return axios.post(`${BASE_URL}/resetpassword`, identity);
  }

  setClinicId(clinicId) {
    localStorage.setItem(CLINIC_ID, clinicId);
  }

  setClinicName(clinicName) {
    localStorage.setItem(CLINIC_NAME, clinicName);
  }

  getClinicId() {
    return localStorage.getItem(CLINIC_ID);
  }

  getAccountId() {
    return localStorage.getItem(ACCOUNT_ID);
  }

  setCredentials(data) {
    localStorage.setItem(TOKEN, this.createJWTToken(data.accessToken));
    localStorage.setItem(
      USER_FULL_NAME,
      data.account.firstName + " " + data.account.lastName
    );
    const roleName =
      data.account.accountRoles[0].roleId === 2 ? "Admin" : "Doctor";
    localStorage.setItem(ROLE, roleName);
    localStorage.setItem(USERNAME, data.account.userName);
    localStorage.setItem(ACCOUNT, data.account);
    localStorage.setItem(ACCOUNT_ID, data.account.id);
    localStorage.setItem(LOGIN_TIME, Date.now());
    this.setupAxiosInterceptors();
  }

  logout() {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(USER_FULL_NAME);
    localStorage.removeItem(USERNAME);
    localStorage.removeItem(ACCOUNT);
    localStorage.removeItem(LOGIN_TIME);
    localStorage.removeItem(ROLE);
    localStorage.removeItem(CLINIC_ID);
  }

  isUserLoggedIn() {
    let username = localStorage.getItem(USERNAME);
    let loginTime = localStorage.getItem(LOGIN_TIME);
    if (this.isUserNameNull(username) || this.isTimeInvalid(loginTime)) {
      localStorage.clear();
      return false;
    }
    return true;
  }

  isUserNameNull(username) {
    return null === username;
  }

  isTimeInvalid(loginTime) {
    const timeDiff = Date.now() - loginTime;
    // expired when time more than 23hours 55minutes
    //const isExpired = timeDiff > 86100000;
    console.log(loginTime);
    console.log(timeDiff);
    const isExpired = timeDiff > 3600000;
    if (isExpired) {
      localStorage.clear();
      window.alert("Token is expired, please login again.");
    }
    return isExpired;
  }

  createJWTToken(token) {
    return "Bearer " + token;
  }

  setupAxiosInterceptors() {
    if (this.isUserLoggedIn()) {
      axios.interceptors.request.use((config) => {
        config.headers.authorization = localStorage.getItem(TOKEN);
        return config;
      });
    }
  }
}

export default new AuthService();
