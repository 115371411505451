import React, { useState, useEffect } from "react";
import AccessTimeRoundedIcon from "@material-ui/icons/AccessTimeRounded";
import moment from "moment-timezone";
import {
  Container,
  Switch,
  Typography,
  Grid,
  TextField,
  Select,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Divider,
  Box,
  AppBar,
  Tabs,
  Tab,
  IconButton,
  MenuItem,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Image from "material-ui-image";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useAppContext } from "../services/authContext";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import AppQuestion from "./AppQuestion";
import AppointmentDataService from "../services/AppointmentDataService";
import "./AppDetailsStyle.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function AppDetails(props) {
  const { displayMessage, clinicTZs, clinicId } = useAppContext();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [status, setStatus] = useState(props.appDetails.status);
  const [reminderType, setReminderType] = useState(0);
  const [reminderDate, setReminderDate] = useState(moment());
  const [reminderTime, setReminderTime] = useState(moment());
  const [reminderConfirm, setReminderConfirm] = useState(false);

  const changeRoom = (event) => {
    props.setRoom(event.target.value);
  };

  function updateRoom() {
    AppointmentDataService.saveRoom(props.appDetails.id, props.room).then(
      (response) =>
        displayMessage("The room number has been successfully updated"),
      (error) => console.log(error)
    );
  }

  const changeReminder = (event) => {
    setReminderType(event.target.value);
  };

  const changeReminderDate = (date) => {
    setReminderDate(date);
  };

  const changeReminderTime = (time) => {
    setReminderTime(time);
  };

  /*
  const changeReminderConfirm = (event) => {
    setReminderConfirm(event.target.checked);
  }; */

  const removeReminder = (id) => (event) => {
    if (window.confirm("Do you really want to delete this reminder?")) {
      AppointmentDataService.removeReminder(id).then(
        (response) => {
          AppointmentDataService.getReminders(props.appDetails.id).then(
            (remindersResponse) => {
              props.setReminders(remindersResponse.data.sort());
            },
            (remindersError) => console.log(remindersError)
          );
          displayMessage("The reminder has been successfully deleted!");
        },
        (error) => console.log(error)
      );
    }
  };

  const addReminder = (event) => {
    let tempReminders = [];
    console.log(reminderDate + " " + reminderTime);
    if (!reminderDate || !reminderTime) {
      window.alert("Invalid date OR invlid time.");
      return;
    }
    /*    props.reminders.forEach((original) => {
      tempReminders.push({
        type:
          original.type === 0 ? "EMAIL" : original.type === 2 ? "VOICE" : "SMS",
        id: original.id,
        requireConfirm: original.confirmed,
        reminderTime: [original.notifyDue + ":01"],
      });
    }); */
    let dateMoment = moment(reminderDate);
    let timeMoment = moment(reminderTime);
    console.log(dateMoment + ":" + timeMoment);
    switch (reminderType) {
      case 0:
        tempReminders.push({
          type: "EMAIL",
          requireConfirm: reminderConfirm,
          reminderTime: [
            transfer2StrFormatWithTZ({
              date: dateMoment,
              time: timeMoment,
            }),
          ],
        });
        break;
      case 1:
        tempReminders.push({
          type: "VOICE",
          requireConfirm: reminderConfirm,
          reminderTime: [
            transfer2StrFormatWithTZ({
              date: dateMoment,
              time: timeMoment,
            }),
          ],
        });
        break;
      case 2:
        tempReminders.push({
          type: "SMS",
          requireConfirm: reminderConfirm,
          reminderTime: [
            transfer2StrFormatWithTZ({
              date: dateMoment,
              time: timeMoment,
            }),
          ],
        });
        // displayMessage("Sms reminder set successfully.");
        break;
      default:
        console.log("no reminder is added!!!");
    }
    AppointmentDataService.saveReminders(
      tempReminders,
      props.appDetails.id
    ).then(
      (response) => {
        AppointmentDataService.getReminders(props.appDetails.id).then(
          (remindersResponse) => {
            let tmpReminders = [];
            remindersResponse.data.forEach((reminder) => {
              console.log(
                moment
                  .tz(reminder.notifyDue, "UTC")
                  .clone()
                  .tz(clinicTZs.get(clinicId))
                  .format("YYYY-MM-DD HH:mm:ss")
              );
              tmpReminders.push({
                ...reminder,
                notifyDue: moment
                  .tz(reminder.notifyDue, "UTC")
                  .clone()
                  .tz(clinicTZs.get(clinicId))
                  .format("YYYY-MM-DD HH:mm:ss"),
              });
            });
            tmpReminders = tmpReminders.sort();
            props.setReminders([...tmpReminders]);
          },
          (remindersError) => console.log(remindersError)
        );

        displayMessage("The reminder has been successfully added!");
      },
      (error) => console.log(error)
    );
  };

  function transfer2StrFormatWithTZ(reminder) {
    let clinicDateTime = moment.tz(
      (typeof reminder.date === "string"
        ? reminder.date
        : reminder.date.format("YYYY-MM-DD")) +
        " " +
        reminder.time.format("HH:mm:ss"),
      clinicTZs.get(clinicId)
    );
    console.log(clinicDateTime);
    console.log(clinicDateTime.utc().format());
    let splittedArray = clinicDateTime.utc().format().split("T");
    console.log(splittedArray);
    return splittedArray[0] + " " + splittedArray[1].substring(0, 8);
  }

  function handleTabChange(event, newValue) {
    setCurrentTabIndex(newValue);
  }

  function changeStatus(event) {
    setStatus(event.target.value);
  }

  function updateStatus(event) {
    if (
      status === "CANCELLED" &&
      !window.confirm("Do you really want to cancel this appointment?")
    ) {
      return;
    }
    AppointmentDataService.updateStatus(props.appDetails.id, status).then(
      (response) => {
        displayMessage("The appointment status has been successfully updated.");
        props.setAppDetails({ ...props.appDetails, status: status });
      },
      (error) => console.log(error)
    );
  }

  function changeComment(event) {
    props.setComments(event.target.value);
    console.log(props.comments);
  }

  function updateComment() {
    console.log(props.comments);
    AppointmentDataService.savePrepareComment(
      props.appDetails.id,
      props.comments
    ).then(
      (response) =>
        displayMessage(
          "The preparation comment has been successfully updated."
        ),
      (error) => console.log(error)
    );
  }

  function dumbQuestionsChange() {
    console.log("dumbQuestionsChange");
  }
  let dt = new Date();
  return (
    <React.Fragment>
      <Typography>
        <IconButton color="primary" onClick={props.showAppList}>
          <ArrowBackIosIcon />
          Back
        </IconButton>
      </Typography>
      <Grid container className="detail-content">
        <Grid item md={4} className="left-container">
          <Typography className="date-area first-line">
            {moment(props.appDetails.appointmentDate).format("YYYY")}
          </Typography>
          <Typography className="date-area">
            {moment(props.appDetails.appointmentDate).format("MMMM Do")}
          </Typography>
          <Typography className="time-area">
            {props.appDetails.startTime.slice(0, 5)}
          </Typography>
          <Typography className="status-area">
            {/* Status: */}
            <Select
              onChange={changeStatus}
              value={status}
              disabled={props.appDetails.status === "CANCELLED" ? true : false}
            >
              <MenuItem value="BOOKED">BOOKED</MenuItem>
              <MenuItem value="CANCELLED">CANCELLED</MenuItem>
              <MenuItem value="CHECKED_IN">CHECKED_IN</MenuItem>
              <MenuItem value="NO_SHOW">NO_SHOW</MenuItem>
              <MenuItem value="CONFIRMED">CONFIRMED</MenuItem>
            </Select>
            <br />
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={updateStatus}
            className="update-btn"
            disabled={props.appDetails.status === "CANCELLED" ? true : false}
          >
            Update
          </Button>
        </Grid>
        <Grid item md={8} className="right-container">
          <Grid container>
            <div className="member-area">
              <Grid item md={3} className="member-image-container">
                <div className="member-image">
                  {props.appDetails.doctorTreatment.doctorGeneralInfo
                    .photoUrl ? (
                    <Image
                      src={
                        props.appDetails.doctorTreatment.doctorGeneralInfo
                          .photoUrl
                      }
                    />
                  ) : null}
                </div>
              </Grid>
              <Grid item md={9}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className="table-td-title">Doctor</TableCell>
                      <TableCell className="table-td-content">
                        Dr.{" "}
                        {
                          props.appDetails.doctorTreatment.doctorGeneralInfo
                            .firstName
                        }
                        {" " +
                        props.appDetails.doctorTreatment.doctorGeneralInfo
                          .middleName
                          ? props.appDetails.doctorTreatment.doctorGeneralInfo
                              .middleName
                          : ""}
                        {" " +
                          props.appDetails.doctorTreatment.doctorGeneralInfo
                            .lastName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="table-td-title">Category</TableCell>
                      <TableCell className="table-td-content">
                        {
                          JSON.parse(
                            props.appDetails.doctorTreatment.treatment
                              .categoryDescription.description
                          ).en
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="table-td-title">Type</TableCell>
                      <TableCell className="table-td-content">
                        {
                          JSON.parse(
                            props.appDetails.doctorTreatment.treatment.name
                          ).en
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="table-td-title">
                        Visit room
                      </TableCell>
                      <TableCell className="table-td-content">
                        <TextField value={props.room} onChange={changeRoom} />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={updateRoom}
                        >
                          Update
                        </Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="table-td-title">
                        Created on
                      </TableCell>
                      <TableCell className="table-td-content">
                          {new Date(Date.parse(props.appDetails.createdDate) - dt.getTimezoneOffset() * 60000).toLocaleDateString().split(" ")[0]}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </div>
            <Divider />
            <div className="member-area">
              <Grid item md={3} className="member-image-container">
                <div className="member-image">
                  {props.patient.photoUrl ? (
                    <Image src={props.patient.photoUrl} />
                  ) : null}
                </div>
              </Grid>
              <Grid item md={9}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className="table-td-title">Patient</TableCell>
                      <TableCell className="table-td-content">
                        {props.patient.firstName}
                        {props.patient.middleName
                          ? " " + props.patient.middleName
                          : ""}
                        {" " + props.patient.lastName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="table-td-title">
                        Medical card number
                      </TableCell>
                      <TableCell className="table-td-content">
                        {props.patient.mediCardNumber}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="table-td-title"></TableCell>
                      <TableCell className="table-td-content">
                        <Button
                          variant="outlined"
                          color="primary"
                          className="into-btn"
                        >
                          Patient info
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <AppBar position="static" color="default" style={{ marginTop: 10 }}>
        <Tabs
          value={currentTabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Pre-Appointment Survey" {...a11yProps(0)} />
          <Tab label="Preparation Comment" {...a11yProps(1)} />
          <Tab label="Reminder" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={currentTabIndex} index={0}>
        <AppQuestion
          questions={props.questions}
          answers={props.answers}
          changeSingleAnswer={dumbQuestionsChange}
          changeTextAnswer={dumbQuestionsChange}
          changeMultiAnswer={dumbQuestionsChange}
          handleFileChange={dumbQuestionsChange}
          disabled={true}
        />
      </TabPanel>
      <TabPanel value={currentTabIndex} index={1}>
        <Typography variant="h5" align="center">
          <TextField
            rows={8}
            variant="outlined"
            multiline
            size="medium"
            style={{ width: 600 }}
            value={props.comments}
            onChange={changeComment}
          />
          <Button variant="contained" color="primary" onClick={updateComment}>
            Save
          </Button>
        </Typography>
      </TabPanel>
      <TabPanel value={currentTabIndex} index={2}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Container maxWidth="md">
            <Card>
              <CardContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Time</TableCell>
                      {/* <TableCell>Need Confirm</TableCell> */}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.reminders
                      .sort((a, b) => {
                        return a.notifyDue.localeCompare(b.notifyDue);
                      })
                      .map((reminder, index) => (
                        <TableRow index={index}>
                          <TableCell>
                            {reminder.type === 1
                              ? "VOICE"
                              : reminder.type === 2
                              ? "SMS"
                              : "EMAIL"}
                          </TableCell>
                          <TableCell>
                            <KeyboardDatePicker
                              margin="normal"
                              id="date-picker-dialog"
                              format="yyyy-MM-dd"
                              value={reminder.notifyDue}
                              disabled
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              value={reminder.notifyDue}
                              KeyboardButtonProps={{
                                "aria-label": "change time",
                              }}
                              disabled
                              keyboardIcon={
                                <AccessTimeRoundedIcon></AccessTimeRoundedIcon>
                              }
                            />
                          </TableCell>
                          {/* <TableCell>
                            <Switch
                              color="primary"
                              name="phoneConfirm"
                              checked={reminder.confirm}
                              disabled
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                            />
                          </TableCell> */}
                          <TableCell>
                            <IconButton>
                              <DeleteForeverOutlinedIcon
                                onClick={removeReminder(reminder.id)}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </CardContent>
              <CardActions>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <FormControl>
                          <Select
                            value={reminderType}
                            displayEmpty
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            onChange={changeReminder}
                          >
                            <MenuItem value={0}>Email</MenuItem>
                            <MenuItem value={1}>Phone</MenuItem>
                            <MenuItem value={2}>SMS</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          format="yyyy-MM-dd"
                          value={reminderDate}
                          onChange={changeReminderDate}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <KeyboardTimePicker
                          margin="normal"
                          id="time-picker"
                          value={reminderTime}
                          onChange={changeReminderTime}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                          keyboardIcon={
                            <AccessTimeRoundedIcon></AccessTimeRoundedIcon>
                          }
                        />
                      </TableCell>
                      {/* <TableCell>
                        <Switch
                          color="primary"
                          name="confirm"
                          checked={reminderConfirm}
                          onChange={changeReminderConfirm}
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                        />
                      </TableCell> */}
                      <TableCell>
                        <Button variant="outlined" onClick={addReminder}>
                          Add
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardActions>
            </Card>
          </Container>
        </MuiPickersUtilsProvider>
      </TabPanel>
    </React.Fragment>
  );
}

export default AppDetails;
